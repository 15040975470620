<template>
  <div class="parameter-row">
    <unit
      v-for="(item, index) of dataArray"
      :key="index"
      class="parameter-row-unit"
      :style="{ left: leftArray[index] }"
      :left="item.title"
      :right="item.info"
    ></unit>
  </div>
</template>

<script>
import Unit from "@c/user-main/breath/breath-section/parameter/parameter-unit.vue";
export default {
  components: {
    Unit,
  },

  props: {
    dataArray: Array,
  },

  data() {
    return {
      leftArray: [
        "0px",
        "174px",
        "371px",
        "568px",
        "765px",
        "933px",
        "1099px",
        "1287px",
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.parameter-row {
  position: relative;
  margin-bottom: 5px;
  height: 25px;
  display: flex;
  align-items: center;

  &-unit {
    position: absolute;
  }
}
</style>