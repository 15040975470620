<!-- user-mian->user-info -->
<template>
  <button class="blue-icon-button" @click="onClick">
    <img class="blue-icon-button-icon" :src="icon" />
    <p class="blue-icon-button-title">{{ title }}</p>
  </button>
</template>

<script>
export default {
  props: {
    title: String,
    icon: {
      type: String,
      default: require("@imgs/user-info-edit.png"),
    },
  },

  methods: {
    onClick() {
      this.$emit("onClick");
    },
  },
};
</script>

<style lang="scss" scoped>
.blue-icon-button {
  display: flex;
  align-items: center;
  //   margin-left: 86px;

  &-icon {
    width: 16px;
    height: 14px;
  }

  &-title {
    color: #2e6be6;
    font-size: 14px;
    margin-left: 3px;
  }
}
</style>