<template>
  <div class="user-main">
    <div class="user-main-container">
      <top-bar type="white" @onBack="onBack" :remindNumber="$store.state.remindNumber" @onClickRemind="onClickRemind">
        <template #top-bar-left>
          <user-main-user-info :patientDetailModel="patientDetailModel" @onEditPatientSucceed="onEditPatientSucceed">
          </user-main-user-info>
        </template>
      </top-bar>
      <div class="user-main-container-gray">
        <div class="user-main-container-gray-top">
          <user-main-breath-machine-info ref="breathMachineInfo"
            :patientDetailModel="patientDetailModel"></user-main-breath-machine-info>
          <user-main-module-selector :selectedIndex="selectedModuleIndex" :dataArray="moduleArray"
            @onModuleSelected="onModuleSelected"></user-main-module-selector>
        </div>
        <component ref="component" class="user-main-container-gray-content" :is="selectedModuleName"
          :patientDetailModel="patientDetailModel">
        </component>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "@c/common/top-bar/top-bar.vue";
import UserMainUserInfo from "@c/user-main/user-main-user-info/user-main-user-info.vue";
import UserMainBreathMachineInfo from "@c/user-main/user-main-breath-machine-info.vue";
import UserMainModuleSelector from "@c/user-main/user-main-module-selector.vue";
import { PatientDetailModel } from "@js/model/patient-detail-model.js";
import EventBus from "@js/event-bus.js";

export default {
  components: {
    TopBar,
    UserMainUserInfo,
    UserMainBreathMachineInfo,
    UserMainModuleSelector,
    //组件
    Holography: () => import("@c/user-main/holography/holography.vue"),
    Baseline: () => import("@c/user-main/baseline/baseline.vue"),
    Trend: () => import("@c/user-main/trend/trend.vue"),
    Sleep: () => import("@c/user-main/sleep/sleep.vue"),
    Breath: () => import("@c/user-main/breath/breath.vue"),
    Record: () => import("@c/user-main/record/record.vue"),
    VisitManage: () => import("@c/user-main/visit-manage/visit-manage.vue"),

  },

  data() {
    return {
      moduleArray: [
        {
          title: "全息视图",
          name: "Holography",
          icon: require("@imgs/user-main-holography.png")
        },
        {
          title: "基线评估",
          name: "Baseline",
          icon: require("@imgs/user-main-baseline.png")
        },
        {
          title: "病情趋势",
          name: "Trend",
          icon: require("@imgs/user-main-trend.png")
        },
        {
          title: "睡眠监测",
          name: "Sleep",
          icon: require("@imgs/user-main-sleep.png")
        },
        {
          title: "呼吸机治疗",
          name: "Breath",
          icon: require("@imgs/user-main-breath.png")
        },
        {
          title: "患者记录",
          name: "Record",
          icon: require("@imgs/user-main-record.png")
        },
        {
          title: "随访管理",
          name: "VisitManage",
          icon: require("@imgs/user-main-visit-manage.png")
        }
      ],
      selectedModuleName: undefined,
      isShowModifyPasswordAlert: false,
      uid: undefined,
      patientDetailModel: undefined,
    };
  },

  computed: {
    selectedModuleIndex() {
      return this.moduleArray
        .map((item) => { return item.name })
        .indexOf(this.selectedModuleName) ?? 0
    }
  },

  created() {
    this.selectedModuleName = this.$router.history.current.query.moduleName;
    this.uid = this.$router.history.current.query.uid;
    this.getPatientDetail();
    EventBus.$on("onPatientInfoChanged", async () => {
      await this.getPatientDetail();
      if (this.selectedModuleName == "Breath") {
        //刷新整个模块
        this.selectedModuleName = undefined
        setTimeout(() => {
          this.selectedModuleName = "Breath"
        }, 1);
      }
    })

  },

  beforeDestroy() {
    EventBus.$off("onPatientInfoChanged")
  },

  methods: {
    onBack() {
      this.$router.push("/");
    },

    onModuleSelected(index) {
      this.selectedModuleName = this.moduleArray[index].name;
    },

    onEditPatientSucceed() {
      this.getPatientDetail();
    },

    async getPatientDetail() {
      try {
        const params = {
          uid: this.uid,
        };
        const data = await this.$api.getPatientDetail(params);
        this.patientDetailModel = new PatientDetailModel(data);
      } catch (error) {
        this.$toast.showRed(error);
      }
    },

    composeSelectedModuleIndex() {
      return this.moduleArray
        .map((item) => { return item.name })
        .indexOf(this.selectedModuleName) ?? 0
    },
    onClickRemind() {
      this.$router.push({ path: "/", query: { selectedIndex: '0' } });
    }
  },
};
</script>

<style lang="scss" scoped>
.user-main {
  &-container {
    min-width: 1800px;
    min-height: 900px;
    display: flex;
    flex-direction: column;

    &-gray {
      background-color: #f2f2f7;
      flex: 1;
      box-sizing: border-box;
      padding: 19px 17px 22px;
      display: flex;
      flex-direction: column;

      &-top {
        background-color: white;
        width: 100%;
        height: 140px;
        display: flex;
        align-items: center;

        &-left {
          border-right: 1px solid #E8E8E8;
          margin-left: 25px;

        }
      }

      &-content {
        background-color: white;
        flex: 1;
        box-sizing: border-box;
        padding: 9px 52px;
        margin-top: 17px;
      }
    }
  }
}
</style>