<template>
    <div>
        <div class="user-main-breath-machine-info">
            <div class="user-main-breath-machine-info-content">
                <div class="user-main-breath-machine-info-content-top">
                    <p v-if="deviceSN" class="user-main-breath-machine-info-content-top-deviceSN">{{ deviceSN }}</p>
                    <button class="user-main-breath-machine-info-content-top-button" @click="onButtonClick">{{
                        buttonTitle
                    }}</button>
                </div>
                <P class="user-main-breath-machine-info-content-mask">面罩：{{ maskTypeName }}</P>
                <p class="user-main-breath-machine-info-content-use-date">
                    最后使用日期：
                    <span class="user-main-breath-machine-info-content-use-date-content"
                        :style="{ color: deviceLastUsedTimeColor }">{{ deviceLastUsedTime }}</span>
                </p>
            </div>
        </div>
        <bind-alert v-if="isShowBindAlert" :initSelectedPatient="patientDetailModel"
            @onCloseClick="onBindAlertCloseClick" @onBindSucceed="onBindSucceed"></bind-alert>
        <unbind-alert v-if="isShowUnbindAlert" :patientDetailModel="patientDetailModel"
            @onCloseClick="onUnbindAlertCloseClick" @onUnbindSucceedClick="onUnbindSucceed"></unbind-alert>
    </div>
</template>

<script>
import PressureSection from "@c/user-main/breath/breath-section/breath-section.vue";
import BlueIconButton from "@c/user-main/common/blue-icon-button.vue";
import ParameterRow from "@c/user-main/breath/breath-section/parameter/parameter-row.vue";
import EventBus from "@js/event-bus.js";
import { DateTool } from "@js/date-tool.js";

export default {
    components: {
        PressureSection,
        BlueIconButton,
        ParameterRow,
        UnbindAlert: () =>
            import(
                "@c/common/alert/panel-alert/panel-alert-unbind-breath-machine.vue"
            ),
        BindAlert: () =>
            import("@c/common/alert/panel-alert/panel-alert-bind-breath-machine.vue"),
    },

    props: {
        patientDetailModel: Object
    },

    data() {
        return {
            deviceSN: undefined,
            deviceDetail: undefined,
            maskTypeName: undefined,
            deviceLastUsedTime: undefined,
            deviceLastUsedTimeColor: undefined,
            isShowUnbindAlert: false,
            isShowBindAlert: false,
        };
    },

    computed: {
        buttonTitle() {
            return this.deviceSN ? "解绑" : "绑定";
        }
    },

    watch: {
        patientDetailModel() {
            this.setupPatientDetail()
        },
    },


    methods: {
        onButtonClick() {
            switch (this.buttonTitle) {
                case "解绑":
                    if (this.deviceSN) {
                        this.isShowUnbindAlert = true;
                    }
                    break;
                case "绑定":
                    this.isShowBindAlert = true;
                    break;
                default:
                    break;
            }
        },

        //绑定面板
        onBindAlertCloseClick() {
            this.isShowBindAlert = false;
        },

        onBindSucceed() {
            this.$toast.showGreen("设备绑定成功");
            this.isShowBindAlert = false;
            this.$emit("onParaAdjustSucceed")
            EventBus.$emit("onPatientInfoChanged")
        },

        //解绑面板
        onUnbindAlertCloseClick() {
            this.isShowUnbindAlert = false;
        },

        onUnbindSucceed() {
            this.$toast.showGreen("设备解绑成功");
            EventBus.$emit("onPatientInfoChanged")
            this.isShowUnbindAlert = false;
        },

        setupPatientDetail() {
            this.deviceSN = this.patientDetailModel?.deviceSN;
            //deviceLastUsedTime
            this.deviceLastUsedTime = placeholderText(this.patientDetailModel?.deviceLastUsedTime)
            this.deviceLastUsedTimeColor = this.composeLastUseTimeColor()
            if (this.deviceSN) {
                //maskTypeName
                this.maskTypeName = this.patientDetailModel?.maskTypeName
            } else {
                this.maskTypeName = placeholderText(undefined)
            }
        },

        composeLastUseTimeColor() {
            const lastUsedTime = DateTool.day(this.patientDetailModel?.deviceLastUsedTime)
            const nowTime = DateTool.day(new Date())
            const diff = nowTime.diff(lastUsedTime) //毫秒
            if (diff) {
                if (diff < 5 * 24 * 60 * 60 * 1000) { // 不到5天，灰色
                    return "#666666";
                } else if (diff < 10 * 24 * 60 * 60 * 1000) { // 5至10天，蓝色
                    return '#2480FF';
                } else if (diff < 15 * 24 * 60 * 60 * 1000) { // 10至15天，黄色
                    return '#FAAD14';
                } else { // 15天及以上，红色
                    return '#FF5B5D';
                }
            } else {
                return "#666666"
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.pressure-parameter {
    &-justify {
        margin-left: 51px;
    }

    &-content {
        display: flex;
        flex-direction: column;
    }
}

.user-main-breath-machine-info {
    width: 258px;
    height: 140px;

    &-content {
        border-right: 1px solid #E8E8E8;
        height: 100%;
        box-sizing: border-box;
        padding: 14px 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        &-top {
            display: flex;

            &-deviceSN {
                color: #666666;
                font-size: 16px;
                line-height: 22px;
                margin-right: 15px;
            }

            &-button {
                color: #5889EB;
                font-size: 12px;
                border-radius: 5px;
                border: 1px solid #5889EB;
                padding: 0 6px;
                height: 22px;
            }
        }

        &-mask {
            color: #666666;
            font-size: 16px;
            line-height: 22px;
        }

        &-use-date {
            color: #666666;
            font-size: 16px;
            line-height: 22px;

            &-content {
                font-size: 16px;
                line-height: 22px;
            }

        }
    }
}
</style>