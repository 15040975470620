import { render, staticRenderFns } from "./breath-section.vue?vue&type=template&id=2d9ee3bd&scoped=true"
import script from "./breath-section.vue?vue&type=script&lang=js"
export * from "./breath-section.vue?vue&type=script&lang=js"
import style0 from "./breath-section.vue?vue&type=style&index=0&id=2d9ee3bd&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d9ee3bd",
  null
  
)

export default component.exports