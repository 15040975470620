<template>
  <div class="breath-section">
    <div class="breath-section-top">
      <p class="breath-section-top-title">{{ title }}</p>
      <slot name="sub-title"></slot>
      <button
        v-if="buttonTitle"
        class="breath-section-top-button"
        @click="onButtonClick"
      >
        {{ buttonTitle }}
      </button>
    </div>
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    buttonTitle: String,
  },

  methods: {
    onButtonClick() {
      this.$emit("onButtonClick");
    },
  },
};
</script>

<style lang="scss" scoped>
.breath-section {
  position: relative;
  display: flex;
  flex-direction: column;

  &-top {
    height: 30px;
    margin-bottom: 10px;
    margin-top: 17px;
    display: flex;
    align-items: center;

    &-title {
      color: black;
      font-size: 16px;
    }

    &-button {
      position: absolute;
      left: 1075px;
      color: black;
      background-color: #e6f7ff;
      font-size: 14px;
      border: 1px solid #91d5ff;
      border-radius: 4px;
      width: 140px;
      height: 32px;
    }
  }
}
</style>
