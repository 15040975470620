<template>
  <unit :left="left" :right="right" minWidth="56px"></unit>
</template>

<script>
import Unit from "@c/user-main/common/unit.vue";

export default {
  components: {
    Unit,
  },

  props: {
    left: String,
    right: String,
  },
};
</script>
