<template>
  <div v-if="patientDetailModel" class="user-main-user-info">
    <div class="user-main-user-info-content">
      <div class="user-main-user-info-content-left">
        <div class="user-main-user-info-content-left-top">
          <p class="user-main-user-info-content-left-top-name">{{ patientDetailModel.name }}</p>
          <p class="user-main-user-info-content-left-top-gender">{{ patientDetailModel.gender }}</p>
          <p class="user-main-user-info-content-left-top-age">{{ patientDetailModel.age }}岁</p>
          <p class="user-main-user-info-content-left-top-mobile"> {{ patientDetailModel.mobile }} </p>
          <button class="user-main-user-info-content-left-top-more" @click="onMore">更多信息</button>
        </div>
        <p class="user-main-user-info-content-left-hospital"> {{ patientDetailModel.hospitalName }} </p>
      </div>
      <tag-view ref="tagView" class="user-main-user-info-content-right" :patientDetail="patientDetailModel.model"></tag-view>
    </div>
    <user-info-alert v-if="isShowUserInfoAlert" :patientDetailModel="patientDetailModel" @onEdit="onUserInfoAlertEdit"
      @onConfirm="onUserInfoAlertConfirm" @onClose="onUserInfoAlertClose">
    </user-info-alert>
    <user-info-edit-alert v-if="isShowUserInfoEditAlert" :patientDetail="patientDetailModel.model"
      @onSucceed="onUserInfoEditAlertSucceed" @onCloseClick="onUserInfoEditAlertClose"></user-info-edit-alert>
  </div>
</template>

<script>
import TagView from "@c/user-main/user-main-user-info/user-main-tag-view.vue";
import UserInfoAlert from "@c/common/alert/panel-alert/panel-alert-user-info-editable.vue"
import UserInfoEditAlert from "@c/common/alert/panel-alert/panel-alert-add-patient/panel-alert-add-patient.vue"
export default {
  components: {
    TagView,
    UserInfoAlert,
    UserInfoEditAlert
  },

  props: {
    patientDetailModel: Object,
  },

  data() {
    return {
      isShowUserInfoAlert: false,
      isShowUserInfoEditAlert: false,
    }
  },

  methods: {
    onMore() {
      this.isShowUserInfoAlert = true
    },

    //UserInfoAlert
    onUserInfoAlertConfirm() {
      this.isShowUserInfoAlert = false
    },

    onUserInfoAlertClose() {
      this.isShowUserInfoAlert = false
    },

    onUserInfoAlertEdit() {
      this.isShowUserInfoAlert = false
      this.isShowUserInfoEditAlert = true
    },

    //UserInfoEditAlert
    onUserInfoEditAlertSucceed() {
      this.isShowUserInfoEditAlert = false
      this.isShowUserInfoAlert = true
      this.$emit("onEditPatientSucceed")
    },

    onUserInfoEditAlertClose() {
      this.isShowUserInfoEditAlert = false
      this.isShowUserInfoAlert = true
    }
  }
};

</script>


<style lang="scss" scoped>
.user-main-user-info {
  flex: 1;
  display: flex;
  align-items: center;

  &-content {
    width: 100%;
    display: flex;

    &-left {
      border-right: 1px solid #E8E8E8;
      height: 60px;
      box-sizing: border-box;
      padding: 4px 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-name {
          color: black;
          font-size: 18px;
          font-weight: 600;
          line-height: 25px;
          max-width: 80px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &-gender {
          color: #666666;
          font-size: 14px;
          margin-left: 10px;
        }

        &-age {
          color: #666666;
          font-size: 14px;
          margin-left: 20px;
        }

        &-mobile {
          color: #666666;
          font-size: 16px;
          margin-left: 10px;
        }

        &-more {
          color: #5889EB;
          font-size: 14px;
          margin-left: 10px;
        }
      }

      &-hospital {
        color: #666666;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

// }
</style>