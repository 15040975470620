<!-- User-Main->User-Info -->
<template>
  <div class="unit">
    <div class="unit-key">
      <p class="unit-key-text" :style="{ minWidth: minWidth }">{{ left }}</p>
      <p>：</p>
    </div>
    <slot name="middle"></slot>
    <p class="unit-value">{{ right }}</p>
    <slot name="tail"></slot>
  </div>
</template>

<script>
export default {
  props: {
    left: String,
    right: String,
    minWidth: {
      type: String,
      default: "70px",
    },
  },
};
</script>

<style lang="scss" scoped>
.unit {
  color: black;
  font-size: 14px;
  line-height: 18px;
  padding-top: 18px;
  display: flex;

  &-key {
    display: flex;

    &-text {
      text-align: justify;
    }

    &-text::after {
      display: inline-block;
      width: 100%;
      content: "";
    }
  }

  &-value {
    font-weight: 300;
    line-height: 18px;
    margin-left: 14px;
    white-space: nowrap;
  }
}
</style>