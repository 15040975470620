import { findKey } from "@js/object-tool.js";
import { DateTool } from "@js/date-tool.js";
class PatientDetailModel {
  // constructor
  constructor(model) {
    this.model = model;
    this.avatar = this.model.headUrl
      ? this.model.headUrl
      : require("@imgs/user-main-avatar.png");
    this.name = placeholderText(model.name);
    this.number = placeholderText(model.number);
    this.birthday = this._initBirthday();
    this.age = placeholderText(`${model.age}`);
    this.gender = placeholderText(findKey(USER_GENDER, model.sex));
    this.mobile = placeholderText(model.mobile);
    this.marrage = placeholderText(findKey(USER_MARRIAGE, model.marry));
    this.education = placeholderText(findKey(EDUCATION_TYPE, model.education));
    this.occupation = placeholderText(model.occupation);
    this.height = placeholderText(model.height, "cm");
    this.weight = placeholderText(model.weight, "kg");
    this.bmi = this._initBMI();
    this.neckCircumstance = placeholderText(model.neck, "cm");
    this.contactTime = this._initContactTime();
    this.address = this._initAddress();
    this.medicineType = placeholderText(model.illness);
    this.medicineHistory = placeholderText(model.ph);
    this.medicineInfo = placeholderText(model.phi);
    this.hospitalName = placeholderText(model.hospitalName);
    //绑定设备信息
    this.deviceSN = model.sn;
    this.maskTypeName = placeholderText(model.maskTypeName);
    this.deviceLastUsedTime = this._initDeviceLastUsedTime();
  }

  // init
  _initBirthday() {
    if (this.model.birthday) {
      return new Date(this.model.birthday);
    } else {
      return null;
    }
  }

  _initBMI() {
    if (!this.model.bmi) {
      const height = parseFloat(this.model.height);
      const weight = parseFloat(this.model.weight);
      if (isNaN(height) || isNaN(weight) || height == 0 || weight == 0) {
        return placeholderText(null);
      } else {
        const rawBMI = weight / Math.pow(height / 100, 2);
        const bmi = rawBMI.toFixed(2);
        return `${bmi}`;
      }
    } else {
      return placeholderText(this.model.bmi);
    }
  }

  _initContactTime() {
    const contactType = findKey(USER_CONTACTTYPE, this.model.contactType) ?? "";
    const contactTime = this.model.contactTime;
    return placeholderText(`${contactType}${contactTime}`);
  }

  _initAddress() {
    const area = this.model.areaName?.split(",").join("");
    const address = this.model.address;
    return placeholderText(area) + placeholderText(address);
  }

  _initDeviceLastUsedTime() {
    return placeholderText(this.model.lastUseTime);
  }
}

export { PatientDetailModel };
