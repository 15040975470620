<template>
    <div ref="tagView" class="tag-view">
        <patient-tag v-for="(item, index) in tagArray" :key="index" :model="item"></patient-tag>
    </div>
</template>

<script>
import PatientTag from "@c/common/patient-tag/patient-tag";
import EventBus from "@js/event-bus.js";
export default {
    components: {
        PatientTag
    },

    props: {
        patientDetail: Object
    },

    data() {
        return {
            tagArray: [],
            currentTagModel: undefined,
            isShowAddTagAlert: false
        }
    },

    created() {
        EventBus.$on("onPatientTagListChanged", () => {
            this.tagListRequest()
        })
    },

    beforeDestroy() {
        EventBus.$off("onPatientTagListChanged")
    },

    mounted() {
        this.tagListRequest()
    },

    methods: {
        async tagListRequest() {
            try {
                const params = {
                    hospitalId: this.patientDetail.hospitalId,
                    userId: this.patientDetail.id
                }
                const rawTagList = await this.$api.getPatientTagList(params)
                const rawTagArray = rawTagList.map((item) => {
                    return {
                        id: item.id,
                        type: item.categoryId,
                        grade: item.levelId,
                        content: item.labelName
                    }
                })
                this.composeTagList(rawTagArray)
            } catch (error) {
                this.$toast.showRed(error)
            }
        },

        composeTagList(rawTagArray) {
            this.tagArray = []
            let tempRow = 1, tempLength = 0
            //tagViewLength
            const tagView = this.$refs.tagView
            const tagViewLength = tagView.scrollWidth - 39 * 2
            for (const item of rawTagArray) {
                //tempLength
                const tagLength = item.content.length * 13 + 9 * 2
                //tempLength
                tempLength += tagLength + 5
                //moreTagLength
                const moreTagLength = 38
                if (tempLength + moreTagLength <= tagViewLength) {
                    this.tagArray.push(item)
                } else {
                    switch (tempRow) {
                        case 1:
                            this.tagArray.push(item)
                            tempRow += 1
                            tempLength = tagLength + 5
                            break;
                        case 2:
                            const moreTag = {
                                type: PATIENT_TAG_TYPE_TYPE.更多,
                                content: "..."
                            }
                            this.tagArray.push(moreTag)
                            return
                        default:
                            break;
                    }
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.tag-view {
    flex: 1;
    box-sizing: border-box;
    padding: 0px 39px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    >.patient-tag {
        cursor: text;
        margin-right: 5px;
        margin-bottom: 9px;
    }

}
</style>