<template>
  <div class="user-main-module-selector">
    <button v-for="(item, index) of dataArray" :key="index" class="user-main-module-selector-item"
      @click="onClick(index)">
      <img class="user-main-module-selector-item-icon"
        :class="{ 'user-main-module-selector-item-icon-selected': index == selectedIndex }" :src="item.icon">
      <p class="user-main-module-selector-item-title"
        :class="{ 'user-main-module-selector-item-title-selected': index == selectedIndex }">{{ item.title }}</p>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    selectedIndex: Number,
    dataArray: Array,
  },

  methods: {
    onClick(index) {
      this.$emit("onModuleSelected", index);
    },
  },
};
</script>

<style lang="scss">
;

.user-main-module-selector {
  background-color: white;
  display: flex;
  align-items: center;

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 60px;

    &-icon {
      border-radius: 6px;
      width: 55px;
      height: 55px;

      &-selected {
        box-shadow: 0px 3px 5px 0px rgba(80, 80, 80, 0.5);
      }
    }

    &-title {
      color: #909090;
      font-size: 16px;
      line-height: 13px;
      margin-top: 22px;

      &-selected{
        color: #5889EB;
      }
    }
  }
}
</style>