<template>
    <panel-alert title="患者信息" width="568px" isShowBottom @onCloseClick="onClose" @onConfirmClick="onConfirm">
        <template v-slot:content>
            <div v-if="patientDetailModel" class="user-info-editable-alert">
                <div class="user-info-editable-alert-top">
                    <div class="user-info-editable-alert-top-row">
                        <unit class="user-info-editable-alert-unit" left="患者姓名" :right="patientDetailModel.name">
                        </unit>
                        <cell-unit class="user-info-editable-alert-edit" color="#2E6BE6"
                            :icon="require('@imgs/panel-alert-edit.png')" title="编辑" cursor="pointer"
                            @onClick="onEdit"></cell-unit>
                    </div>
                    <div class="user-info-editable-alert-top-row">
                        <unit class="user-info-alert-unit" left="患者ID" :right="patientDetailModel.number" isHideOverflow>
                        </unit>
                        <unit class="user-info-alert-unit user-info-editable-alert-unit-right" left="性别"
                            :right="patientDetailModel.gender">
                        </unit>
                    </div>
                    <div class="user-info-editable-alert-top-row">
                        <unit class="user-info-editable-alert-unit" left="出生日期" :right="birthdayStr"></unit>
                        <unit class="user-info-editable-alert-unit user-info-editable-alert-unit-right" left="身高"
                            :right="patientDetailModel.height"></unit>
                    </div>
                    <div class="user-info-editable-alert-top-row">
                        <unit class="user-info-editable-alert-unit" left="体重" :right="patientDetailModel.weight"></unit>
                        <unit class="user-info-editable-alert-unit user-info-editable-alert-unit-right" left="BMI"
                            :right="patientDetailModel.bmi"></unit>
                    </div>
                    <div class="user-info-editable-alert-top-row">
                        <unit class="user-info-editable-alert-unit" left="颈围" :right="patientDetailModel.neckCircumstance">
                        </unit>
                        <unit class="user-info-editable-alert-unit user-info-editable-alert-unit-right" left="婚姻状态"
                            :right="patientDetailModel.marrage"></unit>
                    </div>
                    <div class="user-info-editable-alert-top-row">
                        <unit class="user-info-editable-alert-unit" left="学历" :right="patientDetailModel.education">
                        </unit>
                        <unit class="user-info-editable-alert-unit user-info-editable-alert-unit-right" left="职业"
                            :right="patientDetailModel.occupation"></unit>
                    </div>
                    <div class="user-info-editable-alert-top-row">
                        <unit class="user-info-editable-alert-unit" left="手机号码" :right="patientDetailModel.mobile"></unit>
                        <unit class="user-info-editable-alert-unit user-info-editable-alert-unit-right" left="联系时间"
                            :right="patientDetailModel.contactTime"></unit>
                    </div>
                    <unit class="user-info-editable-alert-unit user-info-editable-alert-unit user-info-editable-alert-long"
                        left="居住地址" :right="patientDetailModel.address"></unit>
                </div>
                <div class="user-info-editable-alert-line"></div>
                <div class="user-info-editable-alert-bottom">
                    <unit class="user-info-editable-alert-unit user-info-editable-alert-unit user-info-editable-alert-long"
                        left="主诉病情" :right="patientDetailModel.medicineType"></unit>
                    <unit class="user-info-editable-alert-unit user-info-editable-alert-unit user-info-editable-alert-long"
                        left="既往病史" :right="patientDetailModel.medicineHistory"></unit>
                    <unit class="user-info-editable-alert-unit user-info-editable-alert-unit user-info-editable-alert-long"
                        left="病史信息" :right="patientDetailModel.medicineInfo"></unit>
                </div>
                <div class="user-info-editable-alert-line"></div>
                <div class="user-info-editable-alert-tag-view">
                    <div class="user-info-editable-alert-tag-view-content">
                        <patient-tag v-for="(item, index) in tagArray" :key="index" :model="item"></patient-tag>
                    </div>
                </div>
            </div>
        </template>
    </panel-alert>
</template>
  
<script>
import PanelAlert from "@c/common/alert/panel-alert/panel-alert.vue";
import Unit from "@c/common/alert/panel-alert/components/key-value-unit.vue";
import CellUnit from "@c/common/paging-list/cell-unit.vue";
import PatientTag from "@c/common/patient-tag/patient-tag";
import { PatientDetailModel } from "@js/model/patient-detail-model.js";
import { DateTool } from "@js/date-tool.js";
export default {
    components: {
        PanelAlert,
        Unit,
        CellUnit,
        PatientTag
    },

    props: {
        patientDetailModel: PatientDetailModel,
    },

    data() {
        return {
            tagArray: []
        }
    },

    computed: {
        birthdayStr() {
            return placeholderText(DateTool.dateToStr(this.patientDetailModel?.birthday, "YYYY年MM月DD日"))
        }
    },

    mounted() {
        this.tagListRequest()
    },

    methods: {
        onEdit() {
            this.$emit("onEdit");
        },

        onClose() {
            this.$emit("onClose");
        },

        onConfirm() {
            this.$emit("onConfirm");
        },


        async tagListRequest() {
            try {
                const params = {
                    hospitalId: this.patientDetailModel.model.hospitalId,
                    userId: this.patientDetailModel.model.id
                }
                const rawTagList = await this.$api.getPatientTagList(params)
                this.tagArray = rawTagList.map((item) => {
                    return {
                        id: item.id,
                        type: item.categoryId,
                        grade: item.levelId,
                        content: item.labelName
                    }
                })
            } catch (error) {
                this.$toast.showRed(error)
            }
        }

    },
};
</script>
  
<style lang="scss" scoped>
/deep/.panel-alert-content {
    padding-right: 24px;
    padding-bottom: 10px;
    padding-top: 0;
}

/deep/.panel-alert-bottom {
    border-top: none;
}

.user-info-editable-alert {
    position: relative;
    display: flex;
    flex-direction: column;

    &-top {
        margin-top: 4px;
        display: flex;
        flex-direction: column;

        &-row {
            position: relative;
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
        }
    }

    &-line {
        background-color: rgba($color: #000000, $alpha: 0.09);
        height: 1px;
        margin: 18px 0px;
    }

    &-bottom {
        display: flex;
        flex-direction: column;
    }

    &-tag-view {
        height: 100px;
        overflow-x: scroll;
        box-sizing: border-box;
        padding: 0 7px 9px 7px;

        &-content {
            display: flex;
            flex-wrap: wrap;

            >.patient-tag {
                margin-right: 5px;
                margin-bottom: 18px;
            }
        }
    }

    &-unit {
        &-right {
            position: absolute;
            left: 300px;
        }
    }

    &-edit {
        margin-left: 40px;
    }

    &-long {
        margin-top: 10px;
        min-height: 28px;
    }
}
</style>